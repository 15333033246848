import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
 

@Component({
  selector: 'app-forum-modal',
  templateUrl: './forum-modal.component.html',
  styleUrls: ['./forum-modal.component.scss']
})
export class ForumModalComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() id:String='';
  loading= false;
  constructor() {}

  ngOnInit(): void { }
  closeModal(){
    console.log('try closing modal')
    if(this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").click();
  }
  create(){}
}
