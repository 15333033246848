import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { InitProvider } from "src/app/init-provider";
import { AuthService } from "src/app/Services/Auth/auth.service";
import { BlogsService } from "src/app/Services/blogs.service";

@Component({
  selector: "app-widgets-card",
  templateUrl: "./widgets-card.component.html",
  styleUrls: ["./widgets-card.component.scss"],
})
export class WidgetsCardComponent implements OnInit {
  public blogId: string = "";
  public loadingIcon: boolean = false;
  public suscriberText: string = "";
  public suscriberImage: any = "";
  public email: any = "";
  constructor(
    public authService: AuthService,
    private blogsService: BlogsService,
    public initProvider: InitProvider,
    private toaster: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.blogId = params.get("id");
      this.initCardSubscribe();
    });
  }

  public sendEmail() {
    this.loadingIcon = true;
    this.blogsService
      .sendEmailBlog(this.blogId, this.email)
      .then((resp) => {
        this.loadingIcon = false;
      //  console.log('test',resp.error.errors.email)
        if (resp.status == "success") {
          this.email = "";
          console.log("Se ejecuta");

          this.toaster.success("El email ha sido enviado");
        } else {
          this.email = "";
          if (resp.error.errors?.email){
            this.toaster.error(resp.error.errors?.email);

          }else{

            this.toaster.error("Error enviando el email");
          }
        }
        console.log(resp);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  public initCardSubscribe() {
    this.suscriberText = this.initProvider.getSettings().suscriber_text;
    this.suscriberImage = this.initProvider.getSettings().suscriber_image;
  }
}
