<div class="container-fluid featured fadeIn">
  <div class="container">
    <div
    *ngIf="loading"
    class="loader-wrapper d-flex justify-content-center my-5"
  >
    <div><i class="fas fa-circle-notch fa-spin fa-2x"></i></div>
  </div>
  <div  *ngIf="!loading"  >
    <div class="row py-5 justify-content-center position">
      <div class="col-md-3 featuredImage featuredInfo px-0">
        <div class="itemImage fadeIn px-0 justify-content-center d-flex">
          <picture>
            <source
              media="(max-width: 799px)"
              [srcset]="InfoProfile.cover_img"
           
            />
            <img
              
              [src]="InfoProfile.cover_img"
              onError="this.src = 'assets/images/mootiva/logo-dark.png'"
              (load)="onImageLoad()"
            />
          </picture>
        </div>
      </div>
      <div
        class="col-md-9 fadeIn d-flex flex-column justify-content-between mt-1"
      >
        <div class="title-post-name">
          <div class="sec-title">
            <h6 class="text-capitalize">
              {{ InfoProfile.title }}
            </h6>
            <div class="d-flex mb-3">
              <span class="date">   {{ InfoProfile.created_at|date:'shortDate' }} - {{InfoProfile?.comments_count?InfoProfile?.comments_count:'0'}} {{'Comment(s)'|translate}}</span>
              
            </div>
          </div>
          <span
            class="content-body"
            [innerHtml]="innerText"
          ></span>
           
        </div>
      </div>
    </div>
    <app-blog-comment [idBlog]="forumId"></app-blog-comment>


    
    
  </div> 



    
  </div> <!-- fin container -->
</div>
