<div class="blog-item">
  <div class="image-wrap mb-4">
    <a (click)="goToProfile(firstPost)" href="javascript:void(0);"
      ><img
        [src]="firstPost?.cover_img"
        class=""
        alt="Image no found"
        width="790"
        height="510"
    /></a>
  </div>
  <div class="post-content">
    <div class="sec-title mb-3">
      <h5 class="font-weight-bold">
        <a (click)="goToProfile(firstPost)" tabindex="-1">{{
          firstPost?.title
        }}</a>
      </h5>
    </div>
    <div class="sec-content mb-3">
      <div class="subtitle" [innerHtml]="firstPost?.subtitle | slice: 0:159"></div>
    </div>
    <!-- <div class="meta-info">
      <span class="author">
        by
        <a class="font-smx" tabindex="0">{{
          firstPost?.external_user_name
        }}</a>
      </span>
      <span class="date">
        Posted On
        <a class="font-smx" tabindex="0">{{
          firstPost?.created_at | date: "short"
        }}</a>
      </span>
    </div> -->
  </div>
</div>
