import { Component,Input, OnInit } from "@angular/core";
import { BlogsService } from "src/app/Services/blogs.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthService } from "src/app/Services/Auth/auth.service";

@Component({
  selector: "app-forum",
  templateUrl: "./forum.component.html",
  styleUrls: ["./forum.component.scss"],
})
export class ForumWidgetComponent implements OnInit {
  public imageLoaded: boolean = false;
  public loaded: boolean = false;
  public comment: string = ""; 
  public forumId: string = "";
  public loading: boolean = false;
  public InfoProfile: any;
  public innerText: any;
  public textValidation: boolean = false;
  public commentPage=1;
  public isLastPage=false;
  @Input() idForum: any;
  loadingIcon: boolean = false;
  public commentaries: any[] = [
  ];

  constructor(
    private blogsService: BlogsService,
    private activatedRoute: ActivatedRoute,
    protected sanitizer: DomSanitizer,
    public authService: AuthService,
  ) { 
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      if (this.idForum){
        this.forumId = this.idForum; //se lo puedo pasar en el input, cuando se llama desdel modal

      }else{
        this.forumId = params.get("id");

      }

      this.getinfoProfile(this.forumId);
    });
  }
  public onImageLoad() {
    this.imageLoaded = true;
  }

  public getinfoProfile(blogId) {
    this.loading = true;
    this.blogsService
      .getBlogProfile(blogId)
      .then((resp) => {
        console.log(resp);
        this.InfoProfile = resp.data;
        this.innerText = this.sanitizer.bypassSecurityTrustHtml(
          this.InfoProfile?.body
        );
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
