import { Injectable } from "@angular/core";
import { ApiService } from "./Api.service";

@Injectable({
  providedIn: "root",
})
export class BlogsService {
  private blogsUrl = "posts";

  // https://luis.mootivaadmin2.staging.keetup.com/api/v1/posts?page=1&order_name=id&order_type=desc&type=blog
  constructor(private apiService: ApiService) {}

  async getBlogs(order = "desc", page = 1, filter = "") {
    try {
      if (filter) {
        filter = "&tag=" + filter;
      }
      return await this.apiService.get(
        this.blogsUrl.concat(
          `?page=${page}&order_type=${order}&type=blog${filter}`
        )
      );
    } catch (error) {
      return error;
    }
  }

  async getHighlightBlogs(order = "desc", highlight = 0) {
    try {
      return await this.apiService.get(
        this.blogsUrl.concat(
          `?page=1&order_name=id&order_type=${order}&type=blog&highlight=${highlight}`
        )
      );
    } catch (error) {
      return error;
    }
  }
  async getBlogProfile(blogId: string) {
    try {
      return await this.apiService.get(this.blogsUrl.concat(`/${blogId}`));
    } catch (error) {
      return error;
    }
  }

  async getTags() {
    try {
      return await this.apiService.get(`post/listtags`);
    } catch (error) {
      return error;
    }
  }
  async filterBytag(tag: string = "") {
    try {
      return await this.apiService.get(`post/listtags`.concat(`?tag=${tag}`));
    } catch (error) {
      return error;
    }
  }

  async getCommentaries(blogId: string,page:number=1,order:string='desc') {
    try {
      return await this.apiService.get(
        "postcomments".concat(`?post_id=${blogId}&page=${page}&order_name=id&order_type=${order}`)
      );
    } catch (error) {
      return error;
    }
  }

  async postCommentaries(blogId: string, commentary: string) {
    try {
      let formData = new FormData();
      formData.append("body", commentary);
      formData.append("post_id", blogId);

      const response = await this.apiService.post("postcomments", formData);
      return response;
    } catch (error) {
      return error;
    }
  }
  async sendEmailBlog(blogId: string, email: string) {
    try {
      const response = await this.apiService.post("suscribeuser", { email });
      return response;
    } catch (error) {
      return error;
    }
  }
}
