import { Injectable } from "@angular/core";
import { ApiService } from "./Api.service";

@Injectable({
  providedIn: "root",
})
export class IaMessagesService {
  constructor(private apiService: ApiService) {}

  async getConversation(user: any, search: string = "", filter: any, page = 1) {
    try {
      let query = `?search=${encodeURIComponent(search)}&page=${page}`;

      if (filter === 0) {
        query += `&user_unread_messages=1`;
      } else if (filter === 1) {
        query += `&user_unread_messages=0`;
      }

      const conversation = await this.apiService.get(
        `users/${user.user_id}/conversations`.concat(query)
      );
      return conversation;
    } catch (error) {
      console.error("Error al obtener conversaciones:", error);
      throw error;
    }
  }
  async getForo(user: any, search: string = "", page = 1) {
    try {
      let query = `?search=${encodeURIComponent(search)}`;

      const conversation = await this.apiService.get(
        `users/${user.user_id}/get_agora_msg?page=${page}`.concat(query)
      );
      return conversation;
    } catch (error) {
      console.error("Error al obtener conversaciones:", error);
      throw error;
    }
  }

  async getMessageOfConversation(user: any, conversationId: number) {
    try {
      const conversation = await this.apiService.get(
        `users/${user.user_id}/conversations/${conversationId}/messages`
      );
      return conversation;
    } catch (error) {
      return error;
    }
  }
  async getMessageOfForo(user: any, conversationId: number) {
    try {
      const conversation = await this.apiService.get(
        `postcomments?post_id=${conversationId}`
      );
      return conversation;
    } catch (error) {
      return error;
    }
  }

  async sendMessageToConversation(
    user: any,
    businessId: any,
    courseId: string,
    newMessage: string
  ) {
    try {
      const sendMessage = await this.apiService.post(
        `users/${user.user_id}/business/${businessId}/messages`,
        {
          relation_id: courseId,
          message: newMessage,
          relation_type: "course",
        }
      );
      return sendMessage;
    } catch (error) {
      return error;
    }
  }

  async sendMessageToForo(newMessage: string, conversationId: string) {
    try {
      const sendMessage = await this.apiService.post(
        `postcomments?post_id=${conversationId}`,
        {
          body: newMessage,
          post_id: conversationId,
        }
      );
      return sendMessage;
    } catch (error) {
      return error;
    }
  }

  async sendMessageToFaqs(user: any, newMessage: string) {
    try {
      const sendMessage = await this.apiService.post(`ai/student_chat`, {
        user_id: user.id,
        question: newMessage,
      });
      return sendMessage;
    } catch (error) {
      return error;
    }
  }
  async getMessageOfFaqs(user: any) {
    try {
      const conversation = await this.apiService.get(
        `ai/history_student_chat/${user.id}?order=ASC`
      );
      return conversation;
    } catch (error) {
      return error;
    }
  }
}
