<!-- <div class="modal fade"  [id]="'modal-' + id" tabindex="-1"
role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" [hidden]="false">
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
<div class="modal-content">
 <div class="modal-header">
   <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
     <span aria-hidden="true">&times;</span>
   </button>
 </div>
 <div class="modal-body">
     vamos a mostrar algo
   <div class="playerWrap">
     <app-forum [idForum]="id"></app-forum>
   </div>
 </div>
</div>
</div>
</div> -->

<div class="modal-close">
    <div
    (click)="closeModal()"
    style="
      color: white;
      cursor: pointer;
      position: absolute;
      top: -25px;
      left: calc(50% - 20px);
    "
  >
    <span>X {{'Close'|translate}}</span>
  </div>
</div>

<div class="modal-body">
    <app-forum [idForum]="id"></app-forum>
</div>