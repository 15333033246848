<div *ngIf="authService.user" class="modal fade " id="get-mp-email-modal" tabindex="-1" role="dialog"
    aria-labelledby="Aviso Legal">
    <div class="modal-dialog-width modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-timezone" style="border-radius: 20px;">

            <div class="modal-body">
                <div class="row justify-content-end mr-1">
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 mt-1 text-center onResponsiveTop mb-5">
                            <h2 class="center-title text-info-timezone">{{ "Hello" | translate }} <span
                                    class="text-info text-info-timezone">{{ authService.user.first_name }}!</span>
                            </h2>

                            <div class="px-3">
                                <div class="row mt-0 no-padding">
                                    <div class="col-12 center-title" style="padding-top: 10px;"> {{ 'Please enter your Mercado Pago email to redirect' | translate}}</div>                                    
                                </div>
                                <div class="row mt-3 no-padding">

                                    <span class="text-info-mp" style="padding-top: 10px;"> {{ 'Mercado Pago Email' |
                                        translate}} </span>
                                    <span style="width: 50%;">
                                        <input type="text" style="width: 100%;" [(ngModel)]="MpEmail"
                                            class="lumi-input-text" />
                                    </span>
                                    <span class="help is-danger" *ngIf="errEmailInvalid">{{'Please enter a valid email' | translate}}</span>

                                </div>


                                <div class="row mt-3 no-padding">
                                    <input (click)="MpEmail=''" [(ngModel)]="notMpEmail" class="checkbox-custom" type="checkbox" value="" id="termscheck">
                                    <label class="checkbox-custom-label text-info-mp" for="termscheck">
                                        {{ "I don't have a Mercado Pago account" | translate }}
                                    </label>
                                </div>


                                <div *ngIf="notMpEmail" class="row mt-3 no-padding text-info-mp">
                                    {{"Don't worry! If you do not have a Mercado Pago account, you can pay with a credit card or debit card."|translate}}

                                </div>
                              
                                <div class="row mt-5 no-padding text-info-mp">
                                    {{"IMPORTANT: After making the payment you must click on the blue button"|translate}}
                                </div>

                                <div class="row mt-0 no-padding text-info-mp">
                                    <img src="assets/images/ml_btn.png" alt="whatsapp" loading="lazy" decoding="async" width="200" height="68">
                                </div>
                            </div>



                            <div class="col-md-12 row mt-3 no-padding"
                                style="display: inline-block;  background: transparent; ">
                                <button class="btn btn-info" (click)="onSaveProfile()">
                                    {{"Pay with Mercado Pago"|translate}}
                                </button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>