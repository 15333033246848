import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-sidebar-post",
  templateUrl: "./sidebar-post.component.html",
  styleUrls: ["./sidebar-post.component.scss"],
})
export class SidebarPostComponent implements OnInit {
  @Input() cardPost: any = [];
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
  
    
  }
}
