
<form #reviewForm="ngForm" (ngSubmit)="onSubmit(reviewForm.value)">
    <div class="question font-weight-bold border-bottom p-3 pl-5">{{texts.q1|translate}}</div>



    <div class="p-3 pl-5">

        <div>
            <input id="radio-1" class="radio-custom" name="liked" value=5 [(ngModel)]="liked" type="radio"
                >
            <label for="radio-1" class="radio-custom-label">{{texts.q1_r1|translate}}</label>
        </div>
        <div>
            <input id="radio-2" class="radio-custom" name="liked" value=4 [(ngModel)]="liked" type="radio">
            <label for="radio-2" class="radio-custom-label">{{texts.q1_r2|translate}}</label>
        </div>
        <div>
            <input id="radio-3" class="radio-custom" name="liked" value=3 [(ngModel)]="liked" type="radio">
            <label for="radio-3" class="radio-custom-label">{{texts.q1_r3|translate}}</label>
        </div>
        <div>
            <input id="radio-4" class="radio-custom" name="liked" value=2 [(ngModel)]="liked" type="radio">
            <label for="radio-4" class="radio-custom-label">{{texts.q1_r4|translate}}</label>
        </div>
        <div>
            <input id="radio-5" class="radio-custom" name="liked" value=1 [(ngModel)]="liked" type="radio">
            <label for="radio-5" class="radio-custom-label">{{texts.q1_r5|translate}}</label>
        </div>
    </div>



    <div class="question font-weight-bold border-bottom p-3 pl-5">{{texts.q2|translate}}</div>
    <div class="p-3 pl-5">
        <div>
            <input id="radio-6" class="radio-custom" name="difficulty" value=5 [(ngModel)]="difficulty" type="radio" >
            <label for="radio-6" class="radio-custom-label">{{texts.q2_r1|translate}}</label>
        </div>
        <div>
            <input id="radio-7" class="radio-custom" name="difficulty" value=4 [(ngModel)]="difficulty" type="radio">
            <label for="radio-7" class="radio-custom-label">{{texts.q2_r2|translate}}</label>
        </div>
        <div>
            <input id="radio-8" class="radio-custom" name="difficulty" value=3 [(ngModel)]="difficulty" type="radio">
            <label for="radio-8" class="radio-custom-label">{{texts.q2_r3|translate}}</label>
        </div>
        <div>
            <input id="radio-9" class="radio-custom" name="difficulty" value=2 [(ngModel)]="difficulty" type="radio">
            <label for="radio-9" class="radio-custom-label">{{texts.q2_r4|translate}}</label>
        </div>
        <div>
            <input id="radio-10" class="radio-custom" name="difficulty" value=1 [(ngModel)]="difficulty" type="radio">
            <label for="radio-10" class="radio-custom-label">{{texts.q2_r5|translate}}</label>
        </div>

    </div>
    <div class="p-3 mt-3 text-right">
        <button class="btn btn-info mr-4" type="submit" [disabled]="(difficulty==0||liked==0)">Continuar</button>
        <button class="btn btn-link mr-3 text-info" (click)="continue()">Omitir</button>
    </div>
</form>
