import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DropletService } from "../../../Services/Droplet.service";

@Component({
  selector: "app-list-blog",
  templateUrl: "./ListBlog.component.html",
  styleUrls: ["./ListBlog.component.scss"],
})
export class ListBlogComponent implements OnInit {
  @Input() firstPost: any;

  constructor(public router: Router) {}

  ngOnInit() {}

  public goToProfile(blog) {
    // this.service.blogDetailContent = blog;
    this.router.navigate(["/blog/profile", blog.id]);
  }
}
