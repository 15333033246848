<div class="sidebar-wrap">
    <div class="row row-cols-1 row-cols-md-1 g-4">
      <div class="col-12">
        <div
          *ngFor="let post of cardPost?.slice(0, 5)"
          class="card"
          [routerLink]="['/blog', 'profile', post.id]"
        >
          <img
            [src]="post.cover_img"
            class="card-img-top side-post-img"
            alt="No image"
            loading="lazy"
          />
          <div class="card-body p-0">
            <h5 class="card-title py-3">
              {{ post?.title }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>