export const environment = {
  production: true,
  apiUrl: 'https://admin.mootivaadmin-dev.staging.keetup.com/api/v1/',
  baseUrl: 'https://admin.mootivaadmin-dev.staging.keetup.com/',
  apiKeyMap: 'AIzaSyBMDhDxtsJolCrpTo5EsLtojSomOzVhqmo',
  paymentsUrl: 'https://fenixpayments.staging.keetup.com/',
  auth: {
    clientSecret: 'xGbAhWL960HycCoboFNvKdhpWJxnLdxIs55Gjn95',
    clientId: '2'
  }
};

