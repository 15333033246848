import {Component, Input,Output, OnInit,EventEmitter} from '@angular/core';
import {AuthService} from 'src/app/Services/Auth/auth.service';
import {ApiService} from '../../Services/Api.service';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-mp-get-email-modal',
  templateUrl: './getmpemail-modal.component.html',
  styleUrls: ['./getmpemail-modal.component.scss']
})
export class GetMpEmailModalComponent implements OnInit {
  @Input() ID: string;
  @Input() type: string;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  
  currentUser;
  errName = false;
  errEmail = false;
  errEmailInvalid = false;
  errPhone = false;
  errPhoneInvalid = false;
  errNameSplit = false;
  errDni = false;
  saveLoading = false;
  notMpEmail=false;
  MpEmail='';
  constructor(
    public authService: AuthService,
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.currentUser = this.authService.user;
    
  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }


  async onSaveProfile() {

    //si el usuario marca que no... sigo
    if (this.notMpEmail){
      this.closeModal.emit('close');
      $('#get-mp-email-modal').modal('hide');

      return
    } 
    
    


    if (!this.validateEmail(this.MpEmail.trim())) {
      this.errEmailInvalid = true;
      return;
    }

 

    this.closeModal.emit(this.MpEmail.trim());
    $('#get-mp-email-modal').modal('hide');

    return


    if (this.errPhone || this.errEmail || this.errDni || this.errName || this.errEmailInvalid || this.errNameSplit) {
      return;
    }
    const saved = await this.apiService.put('users/' + this.currentUser.id, {

      invoice_name: this.currentUser.invoice_name,
      invoice_dni: this.currentUser.invoice_dni,
      invoice_address: this.currentUser.invoice_address,
      invoice_giro: this.currentUser.invoice_giro,
      invoice_razon_social: this.currentUser.invoice_razon_social,
      invoice_email: this.currentUser.invoice_email,
      phone_number_invoice: this.currentUser.phone_number_invoice,

    }).catch(err => err);

    if (saved.error) {
      if (saved.error.errors.email) {
        return alert(saved.error.errors.email[0]);
      } else if (saved.error.errors.first_name) {
        return alert(saved.error.errors.first_name[1]);
      } else if (saved.error.errors.last_name) {
        return alert(saved.error.errors.last_name[1]);
      }
    }
    console.log('aaa saved!', saved);
    console.log(saved);
    this.authService.saveUserData();
    this.saveLoading = false;
    $('#modal-timezone').modal('hide');
    if (!this.type){
       this.closeModal.emit('close');
    }
    if (this.type === 'Course') {
      console.log('Course');
      // this.router.navigate(['/courses/purchase', this.ID]);
      this.router.navigate(['/purchases', 'course', this.ID]);
    }

    if (this.type === 'Plan') {
      console.log('Plan');
      this.router.navigate(['/purchases', 'plan', this.ID]);
      //this.router.navigate(['/logged/profile/payment'], {queryParams: {plan: this.ID}});
    }

    if (this.type === 'Event') {
      console.log('Event');
      this.router.navigate(['/purchases', 'event', this.ID]);
      // this.router.navigate(['/events/register', this.ID]);
    }
  }
}
