import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { CoursesService } from 'src/app/Services/Courses.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/Api.service';

@Component({
  selector: 'app-reviewform',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss']
})
export class ReviewFormComponent implements OnInit {
  @Input() extra = null;
  @Input() courseId = null;
  @Input() course = null;
  @Input() jsonData = null;
  @Output() onContinue = new EventEmitter<string>();
  user;
  niveles = [];
  texts=null;
  liked=0;
  difficulty=0;
  jsonInfo=null
  constructor(public authService: AuthService, public courseService: CoursesService, private router: Router
    , private apiService: ApiService) {
  }

  ngOnInit() {
    this.jsonInfo=JSON.parse(this.jsonData);
    this.texts = {
      'q1'    : 'Did you like this course?',
      'q2'    : 'How difficult was the course for you?',
      'q1_r1' : 'Yes, very satisfied',
      'q1_r2' : 'Yes, satisfied',
      'q1_r3' : 'More or less',
      'q1_r4' : 'No, not very satisfied',
      'q1_r5' : 'No, dissatisfied',
      'q2_r1' : 'Very difficult',
      'q2_r2' : 'Difficult',
      'q2_r3' : 'Normal',
      'q2_r4' : 'Easy',
      'q2_r5' : 'Very easy',

  };
  if (this.jsonInfo){
    this.texts = {
      'q1' : this.jsonInfo.satisfaction_questions[0].question,
      'q2'  : this.jsonInfo.satisfaction_questions[1].question,
      'q1_r1' : this.jsonInfo.satisfaction_questions[0].resp1,
      'q1_r2' : this.jsonInfo.satisfaction_questions[0].resp2,
      'q1_r3' : this.jsonInfo.satisfaction_questions[0].resp3,
      'q1_r4' : this.jsonInfo.satisfaction_questions[0].resp4,
      'q1_r5' : this.jsonInfo.satisfaction_questions[0].resp5,
      'q2_r1' : this.jsonInfo.satisfaction_questions[1].resp1,
      'q2_r2' : this.jsonInfo.satisfaction_questions[1].resp2,
      'q2_r3' : this.jsonInfo.satisfaction_questions[1].resp3,
      'q2_r4' : this.jsonInfo.satisfaction_questions[1].resp4,
      'q2_r5' : this.jsonInfo.satisfaction_questions[1].resp5,
    };
  }

    console.log(' this.jsonInfo',this.jsonInfo)
    this.user = JSON.parse(localStorage.getItem('user'));
    if (!this.course) {
      this.courseService.getCourseById(this.courseId).then((courseData) => {
        this.course = courseData.data;
        this.course.lessons.data = this.course.lessons.data.map((course) => {
          return { ...course, expand: false };
        });



      }).catch((error) => {
        console.log(error);
        this.router.navigate(['home']);
      });
    } else {
      this.course.lessons.data = this.course.lessons.data.map((course) => {
        return { ...course, expand: false };
      });


    }
  }


  continue() {
      this.difficulty=0;
      this.liked=0;
      $(".modal-backdrop").remove();
      this.onContinue.emit();
  }

  async onSubmit(form) {
    const reviewToStore = {
      course: this.course.id,
      liked: this.liked,
      difficulty: this.difficulty
    }
    try {
      const response = await this.courseService.sendCourseReview(reviewToStore);
      this.continue();
    } catch (error) {
      console.log(error);
    }
  }
}
