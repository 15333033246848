<!-- <div class="row justify-content-center mt-5"> -->

<div class="row mt-0">
    <div class="user-comment-wrap mt-0 col-12">
        <div class="commentary-box" *ngIf="authService.user">
            <div class="form-group col-12 col-md-12 p-0 d-flex">
                <span class="">
                    <img alt="blog thumb" class="img-fluid rounded-circle img-comment img-user"
                        [src]="authService.user.hero_image.small" width="100" height="100" />
                </span>
                <textarea name="name-input" class="form-control px-2" row="5" [placeholder]="'Write a comment'|translate"
                    [(ngModel)]="comment"></textarea>
            </div>

            <div class="row justify-content-start">
                <div class="col-5 btn-comment">
                    <button [disabled]="comment == '' || comment.length < 10" class="btn btn-info" (click)="postComment()">
                        {{ "Send comment" | translate }}
                        <i *ngIf="loadingIcon" class="fas fa-circle-notch fa-spin fa-1x"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="user-comment" [scrollWindow]="false" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
            (scrolled)="onScroll()">

            <div *ngFor="let comment of commentaries" class="comment-item my-5">

                <div class="media d-block d-sm-flex">
                    <div class="image-wrap mb-sm-0 mb-2 mr-0 mr-sm-4">
                        <span class="d-inline-block">
                            <img alt="blog thumb" class="border-img img-fluid rounded-circle"
                                [src]="comment?.user?.hero_image.small" width="100px" height="100px" />
                        </span>
                    </div>
                    <div class="media-body">
                        <div class="sec-title  d-sm-flex align-items-center justify-content-between">
                            <h6 class="media-heading d-inline-block mb-0">
                                {{ comment.user.last_name+' '+comment.user.first_name }}
                            </h6>
                        </div>
                        <span class="text-muted d-block d-sm-inline-block"><i class="fa fa-clock-o"></i>
                            {{ comment.created_at  }}
                        </span>
                        <div class="sec-content mb-3">
                            <p class="comment-body">
                                {{ comment.body }}
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div *ngIf="loading" class="loader-wrapper d-flex justify-content-center ">
                <div><i class="fas fa-circle-notch fa-spin fa-2x"></i></div>
            </div>
        </div>
        
 
    </div>
</div>