<div class="card">
  <img
    [src]="suscriberImage?.medium"
    class="card-img-top"
    alt="No image"
    loading="lazy"
  />
  <div class="card-body p-4">
    <h5 class="card-title py-3">
      {{ suscriberText || "Subscríbete para obtener el contenido" }}
    </h5>
    <div class="row m-1">
      <input
        type="text"
        style="width: 100%"
        class="lumi-input-text"
        placeholder="Ingresa tu email para subscribirte"
        [(ngModel)]="email"
      />
    </div>

    <div class="row justify-content-center py-4 text-center">
      <div class="col-10">
        <button
          [disabled]="email == ''"
          (click)="sendEmail()"
          class="btn btn-primary"
        >
          {{ "Quiero Subscribirme" | translate }}
          <i *ngIf="loadingIcon" class="fas fa-circle-notch fa-spin fa-1x"></i>
        </button>
      </div>
    </div>
  </div>
</div>
