import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { InitProvider } from "src/app/init-provider";
import { ApiService } from "src/app/Services/Api.service";
import { AuthService } from "src/app/Services/Auth/auth.service";
import { UtilitiesService } from "src/app/Services/utilities.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  @Output() changeView = new EventEmitter<any>();
  @Input() courseId: string;
  @Input() eventId: string;
  @Input() productId: string;
  @Input() programId: string;
  @Input() is_just_for_suscription: any = false;
  public form: FormGroup;
  emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  loading = false;
  showRegisterError = false;
  registerErrorMessage = "Hubo un error en el registro";
  public company: any;
  categories = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    initProvider: InitProvider,
    private apiService: ApiService,
    private utilitie: UtilitiesService
  ) {
    this.company = initProvider.getSettings();
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.courseId, this.eventId);
  }
  ngOnInit() {
    this.form = this.fb.group(
      {
        firstName: ["", Validators.required],
        email: [
          "",
          [Validators.required, Validators.pattern(this.emailPattern)],
        ],
        newPassword: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", [Validators.required, Validators.minLength(6)]],
        tac: ["", [Validators.requiredTrue]],
      },
      {
        validator: this.MatchPassword, // your validation method
      }
    );
  }

  /*
   * Match Password Custom validation
   */
  MatchPassword(AC: AbstractControl) {
    const password = AC.get("newPassword").value; // to get value in input tag
    const confirmPassword = AC.get("confirmPassword").value; // to get value in input tag
    if (password != confirmPassword) {
      AC.get("confirmPassword").setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }

  async signUp() {
    this.utilitie.setSharingObservable = {
      isPurchasing: true,
      courseId: this.courseId,
      eventId: this.eventId,
    };

    if (this.form.valid) {
      this.loading = true;
      const nameArray = this.form.get("firstName").value.trim().split(" ");
      if (nameArray.length < 2) {
        this.loading = false;
        return this.form.controls["firstName"].setErrors({
          fullNameRequired: true,
        });
      } else {
        let firstNames = "";
        let lastName = "";
        nameArray.map((name, i) => {
          if (i != nameArray.length - 1) {
            firstNames = firstNames.concat(name + " ");
          } else {
            lastName = name;
          }
        });
        try {
          await this.authService.register(
            firstNames,
            lastName,
            this.form.get("email").value,
            this.form.get("newPassword").value
          );

          // se setean las categorias por default para evitar el qust inicial
          await this.setDefaultCategories();
          this.loading = false;
          //this.router.navigate(["/logged/home"]);
          let param=''
          if (this.courseId){
            param='purchased_id='+this.courseId+'&purchased_type=course'
          }
          if (this.eventId){
            param='purchased_id='+this.eventId+'&purchased_type=event';
          }
          if (this.productId){
            param='purchased_id='+this.productId+'&purchased_type=product';
          }
          if (this.programId) {
            param='purchased_id='+this.programId+'&purchased_type=program';

          }

          if (this.is_just_for_suscription) {
            window.location.href = "/logged/profile?section=plans";
          }else{
            window.location.href = "/logged/home?"+param;
          }
        } catch (error) {
          this.showRegisterError = true;
          this.loading = false;
          if (error.error.errors["email"]) {
            this.registerErrorMessage = error.error.errors["email"][0];
          }
        }
      }
    } else {
      this.loading = false;
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
    }
  }

  async setDefaultCategories() {
    const categoriesApi = await this.apiService.get("categories?from_quizzes");
    this.categories = categoriesApi.data.filter(
      (c) => c.parent == null || c.parent.length <= 0
    );

    const options = {
      id: this.authService.user.id,
      category_id: null,
      category_2_id: null,
      category_3_id: null,
      availability: 1,
    };
    this.categories.map((c) => {
      if (!options.category_id) {
        options.category_id = c.id;
        return;
      }
      if (!options.category_2_id) {
        options.category_2_id = c.id;
        return;
      }
      if (!options.category_3_id) {
        options.category_3_id = c.id;
        return;
      }

      return;
    });

    const saved = await this.apiService.put(
      "users/" + this.authService.user.id,
      options
    );

    const s = await this.authService.saveUserData();
  }
  public goToLogin() {
    this.changeView.emit("login");
  }
}
