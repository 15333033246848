import {CompanyModel} from './CompanyModel';

export class CourseModel {
  activation_mode: 'immediate' | 'progressive';
  activation_days: number;
  category: any;
  company: CompanyModel;
  count: number;
  description: string;
  discount_has: boolean;
  discount_rate?: number;
  discount_until?: number;
  exam?: number;
  exams_are_enabled: boolean;
  free_token?: string;
  id: number;
  image: string;
  image_mobile: string;
  is_free: boolean;
  is_just_for_purchase: boolean;
  is_just_for_suscription: boolean;
  lessons: any;
  level: Object;
  name: string;
  price: number;
  price_global: number;
  purchased: boolean;
  purchased_at: string;
  questions: number;
  teacher: any;
  test: string;
  with_installments: boolean;
  installments?: number;
  discount_remaining?: string;
  preview_video?: any;
  first_course_interation:any;
  advertising_images:any;
  display_mode:any;
  short_description: string ;
  
}
