import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CoursesService } from "src/app/Services/Courses.service";
import * as Player from "@vimeo/player/dist/player.js";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/Services/Auth/auth.service";
import { DomSanitizer } from "@angular/platform-browser";
import { CompanyModel } from "../../Models/CompanyModel";
import { InitProvider } from "../../init-provider";
import { PlayerService } from "../../Services/player.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ForumModalComponent } from "src/app/modals/forum-modal/forum-modal.component";
import * as moment from "moment";
import { CourseModel } from "../../Models/CourseModel";
import { TranslateService } from "@ngx-translate/core";
import {TrackingService} from '../../Services/tracking.service';

declare var $;

@Component({
  selector: "app-lessonbox",
  templateUrl: "./lesson-box.component.html",
  styleUrls: ["./lesson-box.component.scss"],
})
export class LessonBoxComponent implements OnInit {
  @ViewChild("closeModal") closeModal: ElementRef<HTMLInputElement>;
  @Input() course: CourseModel;
  @Input() index: number;
  @Input() lesson;
  @Input() textLesson = "Unit";
  @Input() openBox = false;
  @Input() isLast = false;
  @Input() available = false;
  @Input() unavailableMsg =
    "Para acceder al contenido de las lecciones debe comprar el curso";
  @ViewChild("modalContainer") elementRef: ElementRef;
  videoPicture;
  lessonImage;
  videoFrame;
  loadingVideo: boolean[];
  videos: any[] = [];
  videoLoaded = false;
  isLessonBoxShown = false;
  lessonLetter = "U";
  textFinish: string = "Finalizar";
  iframeUrl: any;
  company: CompanyModel;
  @Output() boxExpanded = new EventEmitter<string>();
  @Output() allDone = new EventEmitter<boolean>();
  @Output() done = new EventEmitter<number>();
  public nextButtonText: string = 'Next Lesson'
  constructor(
    private courseService: CoursesService,
    private router: Router,
    private toaster: ToastrService,
    public authService: AuthService,
    private sanitizer: DomSanitizer,
    private initProvider: InitProvider,
    private playerService: PlayerService,
    private ngbModal: NgbModal,
    private trackingService: TrackingService,
    private translate: TranslateService
  ) {
    this.company = initProvider.getSettings();
    this.playerService.events.subscribe((data) => {});
  }

  ngOnInit() {
    for (let i = 0; i < 25; i++) {
      this.getThumb(i);
    }
    console.log('videos',this.videos)
    if (this.openBox) {
      this.lesson.expand = true;
    }
    this.lessonImage = this.lesson?.image?.medium;
    this.lessonLetter = this.textLesson.charAt(0);

    const user = this.authService.user;
    const company = this.initProvider.getSettings();
    this.company = company;
    const json = JSON.parse(company.faq_json);
    const user_language = user ? user.language : company.language;

    if (user_language === "en") {
      this.textFinish = json.texts?.course_finish_link_en ? json.texts.course_finish_link_en : "Finish";
      this.nextButtonText = json.texts?.course_next_lesson_link_en ? json.texts.course_next_lesson_link_en : "Next Lesson";
    }
    if (user_language === "es") {
      this.textFinish = json.texts?.course_finish_link_es ? json.texts.course_finish_link_es : "Finalizar";
      this.nextButtonText = json.texts?.course_next_lesson_link_es ? json.texts.course_next_lesson_link_es : "Siguiente lección";
    }
  }

  getThumb(i) {
    const key = i === 0 ? "videoModel" : `videoModel${i + 1}`;
    // console.log('getThumb', key, this.lesson[key]);
    // if (this.lesson[key]) {
    this.videos[i] = {
      id: this.lesson[key] ? this.lesson[key].id : null,
      picture: this.lesson[key]
        ? this.lesson[key].thumbnail.replace("small", "medium")
        : null,
    };
    // }
  }
  downloadFile(i){
  
    let data={
      track                  : 'file_download',
      value                  : 1,
      is_permanent           : true,
      description            : 'download lesson file element_id=course id; extra_info_1=lessonId; extra_info_2=number of file ',
      user_id                : this.authService.user?this.authService.user.id:null,
      element_id             : this.course.id,
      extra_info_1           : this.lesson.id,
      extra_info_2           : i==0?0:i+1, 
      is_first_interaction   : this.course.first_course_interation,

    }
    this.trackingService.trackData(data)
  }
  toggleVisibility() {
    if (!this.available) {
      this.toaster.error(this.unavailableMsg);
      return;
    }

    if (
      this.course.activation_mode === "progressive" &&
      this.progressiveDateIsInTheFuture()
    ) {
      if (!this.course.purchased) {
        this.toaster.error(
          this.translate.instant("This lesson will be available after") +
            " " +
            this.progressiveDateForLessonWhenNotPurchased()
        );
        return;
      }
      this.toaster.error(
        this.translate.instant("This lesson will be available after") +
          " " +
          this.progressiveDateForLesson()
      );
      return;
    }
    let data={
      track                  : '',
      value                  : 1,
      is_permanent           : true,
      description            : '',
      user_id                : this.authService.user?this.authService.user.id:null,
      element_id             : this.course.id,
      extra_info_1           : this.lesson.id,
      extra_info_2           : null,
      is_first_interaction   : this.course.first_course_interation,

    }

    if (!this.lesson.expand) {
      this.boxExpanded.emit(this.lesson.id);
      data.track='lesson_open';
      data.description='Lesson open; element id= course id; extra_info_1=lesson id';
       
    } else {
      this.lesson.expand = false;
       
      data.track='lesson_close';
      data.description='Lesson close; element id= course id; extra_info_1=lesson id';

    }
    this.trackingService.trackData(data)

  }

  initializeVideoPlayer(videoID) {
    // const player = new Player(videoID, {
    //   id: videoID,
    //   responsive: true
    // });

    // player.on('ended', () => {
    //   // this.changeLessonStatus(true);
    // });
    //
    // player.on('loaded', () => {
    //   const padding = player.element.parentNode.style.paddingTop.replace('%', '');
    //   if (padding > 75) {
    //     player.element.parentNode.style.paddingTop = '75%';
    //   }
    //   this.videoLoaded = true;
    // });

    $("#modal-" + videoID).on("hide.bs.modal", () => {
      // player.unload();
      this.videoLoaded = false;
      let data={
        track                  : 'video_lesson_modal_close',
        value                  : 1,
        is_permanent           : true,
        description            : 'Video lesson close element_id=course id; extra_info_1=lessonId; extra_info_2=videoId',
        user_id                : this.authService.user?this.authService.user.id:null,
        element_id             : this.course.id,
        extra_info_1           : this.lesson.id,
        extra_info_2           : videoID, 
        is_first_interaction   : this.course.first_course_interation,
  
      }
      this.trackingService.trackData(data)
    });
  }

  showForumModal(id) {
    const modalRef = this.ngbModal.open(ForumModalComponent, {
      windowClass: "default-modal",
      size: "lg",
    });
    modalRef.componentInstance.id = id;
  }

  openVideoModal(videoID) {
    this.videoLoaded = true;
    let data={
      track                 : 'video_lesson_modal_open',
      value                 :  1,
      is_permanent          : true,
      description           : 'Video lesson open element_id=course id; extra_info_1=lessonId; extra_info_2=videoId',
      user_id               : this.authService.user?this.authService.user.id:null,
      element_id            : this.course.id,
      extra_info_1          : this.lesson.id,
      extra_info_2          : videoID,
      is_first_interaction  : this.course.first_course_interation,

    }
    this.trackingService.trackData(data)

    setTimeout(() => {
      this.initializeVideoPlayer(videoID);
    }, 200);
  }

  changeLessonStatus(status) {
    if (status) {
      this.done.emit(this.lesson.id);
    }
    // if not loger and free course then no need to call
    const user = this.authService.user;
    if (user) {
      this.courseService
        .changeLessonStatus(status, this.lesson.id)
        .then((data) => {
          this.lesson.approved = status;
          this.lesson.expand = false;
          this.isLessonBoxShown = false;

          if (this.closeModal) {
            this.closeModal.nativeElement.click();
          }

          if (data.status === "finished") {
            if (data.extra !== null) {
              this.allDone.emit(true);
              // this.router.navigateByUrl( "/courses/review/" + this.lesson.course + "?extra=" + data.extra.id );
            } else {
              this.allDone.emit(false);
              // this.router.navigateByUrl("/courses/review/" + this.lesson.course);
            }
          } else if (data.extra !== null) {
            this.router.navigateByUrl("/courses/extra/" + data.extra.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.lesson.approved = true;
    }
  }

  progressiveDateForLesson() {
    return moment(this.course.purchased_at)
      .add(this.course.activation_days * this.index, "days")
      .format("DD/MM/YYYY");
  }

  progressiveDateForLessonWhenNotPurchased() {
    return moment()
      .add(this.course.activation_days * this.index, "days")
      .format("DD/MM/YYYY");
  }

  progressiveDateIsInTheFuture() {
    // First lesson is always available
    if (this.index === 0) {
      return false;
    }

    const from = this.course.purchased_at
      ? moment(this.course.purchased_at)
      : moment();
    return from
      .add(this.course.activation_days * this.index, "days")
      .isAfter(moment().endOf("day"));
  }
}
