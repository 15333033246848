import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { InfoRedirect } from "../Interfaces/info-redirect";


@Injectable({
  providedIn: "root",
})
export class UtilitiesService {
  public $infoRedirect: BehaviorSubject<InfoRedirect> =
    new BehaviorSubject<InfoRedirect>({
      isPurchasing: false,
      courseId: "",
      eventId: "",
    });

  constructor() {
   // console.log('contrustor del servicio');
    
  }

  get getSharingObservable() {
    return this.$infoRedirect.getValue();
  }
  set setSharingObservable(data: InfoRedirect) {
    this.$infoRedirect.next(data);
  }
}
