import { Component, Input, OnInit } from '@angular/core';
import { BlogsService } from "src/app/Services/blogs.service";
import { AuthService } from "src/app/Services/Auth/auth.service";


@Component({
  selector: 'app-blog-comment',
  templateUrl: './BlogComment.component.html',
  styleUrls: ['./BlogComment.component.scss']
})
export class BlogCommentComponent implements OnInit {

  @Input() idBlog: any;
  public commentPage = 1;
  public isLastPage = false;
  public commentaries: any[] = [];

  public comment: string = "";
  public loading: boolean = false;
  public loadingIcon: boolean = false;
  public textValidation: boolean = false;

  constructor(
    private blogsService: BlogsService,
    public authService: AuthService,
  ) {

  }

  ngOnInit() {
    this.listCommentaries(this.idBlog);
  }

  public postComment() {
    if (this.comment.length < 1) {
      this.textValidation = true;
      return;
    }
    this.textValidation = false;
    this.loadingIcon = true;
    this.blogsService
      .postCommentaries(this.idBlog, this.comment)
      .then((resp) => {
        this.loadingIcon = false;
        console.log('comentario que llego',resp);
        if (resp.status == "success") {
          this.comment = "";
          this.commentaries.unshift(resp.data)
          
        }
        if (resp.status == 422) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }



  public listCommentaries(idBlog: string) {
    this.loading = true;
    this.blogsService.getCommentaries(idBlog, this.commentPage).then((commentaries) => {

      this.commentaries = commentaries.data;
      
      this.loading = false;

      if (commentaries.paginator.next_page_url == null) {
        this.isLastPage = true;
      }

    });
  }
  public nextPage() {
    let self=this;
    this.loading=true;

    this.blogsService.getCommentaries(this.idBlog, this.commentPage).then((commentaries) => {
    
      commentaries.data.forEach((item) => {
        this.commentaries.push(item);
      });
      self.loading=false;
      if (commentaries.paginator.next_page_url == null) {
        this.isLastPage = true;
      }
    });
  }



  public onScroll() {
    console.log('testing....!! scrol...')
    if (!this.isLastPage) {
      this.commentPage++;
      this.nextPage();
    }

  }





}
